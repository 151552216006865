/*
  TextArea
  ----------------------------------------------------------------------------------------------------------------------

  This functional component is responsible for rendering the required text-area fields inside the Form to
  display and update the property properly, so that it can be edited by the user.
  Functional component because no state is being tracked by this component.

  Props
  ----------------------------------------------------------------------------------------------------------------------

  * label: The label to display with the input.
  * name: The name of the property being rendered.
  * value: The subtitle value of the property, tracked through state.
  * onChange: Callback function invoked when the input value changes.
*/
import React from "react";
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onInputChanged: PropTypes.func
};

const TextArea = ({label, name, value, onInputChanged}) => (
  <div className="sb-Form-group">
    <label>{label}</label>
    <textarea value={value} name={name} onChange={onInputChanged} />
  </div>
);

TextArea.propTypes = propTypes;

export default TextArea;
