  /*
  Spinner Component
  -----------------

  This Component will create a simple Spinner on the screen, indicating that the ReactJS framework is busy loading
  information from the backend or processing/waiting on a specific event. The image is served through rails,
  but we want to use a component to properly wrap any logic that might be needed for getting the image URL or
  other requirements. Since we do not track state, we can use a PureComponent for this.
 */
import React from 'react'

export default class Spinner extends React.PureComponent {
  render() {
    return <img className="Spinner" src={window.assets.spinner_url} alt="Loading..." />;
  }
}
