import React, { useEffect, useState } from 'react';

const DefaultColorModal = ({ handleClose, show, selectedStyle, onModalInputChange, stylePreferredColorOverrides }) => {
  const [colorId, setColorId] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const showHideClassName = show ? "admin-CategoryModal display-block" : "admin-CategoryModal display-none";
  const messageClassName = showMessage ?  "admin-CategoryModal-message display-block" : "admin-CategoryModal-message display-none"

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleClose();
  };

  const handleChange = (evt) => {
    evt.preventDefault();
    setColorId(evt.target.value);
  };

  const modalClick = (evt) => {
    evt.stopPropagation();
  }

  useEffect(() => {
    const inputStatus = onModalInputChange(colorId);
    setShowMessage(!inputStatus);
  }, [colorId] );

  useEffect(() => {
    setColorId(stylePreferredColorOverrides[selectedStyle.id]);
  }, [stylePreferredColorOverrides, selectedStyle] );

  return (
    <div className={showHideClassName} onClick={handleClose}>
      <div className="admin-CategoryModal-content" onClick={modalClick}>
        <section className="admin-CategoryModal-main">
          <button type="button" onClick={handleClose} className="admin-CategoryModal-close">
              &times;
          </button>
          <h3 align="center"> Enter the Color ID  for {selectedStyle.name}</h3>
          <span align="center" className={messageClassName}>* Please Enter a valid Color Id.</span>
          <form align="center" onSubmit={handleSubmit}>
            <label className="default-color-label admin-CategoryModal-label">
              Default Color ID:
              <input type="text"
              inputMode="numeric"
              name="default color"
              className="admin-CategoryModal-input"
              onChange={handleChange}
              pattern="\d*"
              placeholder=""
              value={colorId || ""}
              />
            </label>
            <br></br>
            <input type="submit" value="Apply" className="sb-Btn admin-CategoryModal-save-button"/>
          </form>
        </section>
      </div>
    </div>
  );
};

export default DefaultColorModal;
