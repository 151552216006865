/*
  CardContainer Component
  -----------------------

  This component is a container that will hold all Style cards, allowing them to be pinned and sorted, saving their
  position for the Category that's currently being configured. The logic is maintained through the parent, and this
  component will be a simple functional that only cares about receiving the collection to display.

 */
import React from 'react';
import PropTypes from 'prop-types';
import Sortable from 'react-sortablejs';
import StyleCard from './StyleCard';

const propTypes = {
  styles: PropTypes.array,
  onChange: PropTypes.func,
  pinnedStyles: PropTypes.array,
  onPinnedClick: PropTypes.func,
  colorOverrides: PropTypes.objectOf(PropTypes.number),
  onColorClick: PropTypes.func
};
const CardContainer = ({
  styles,
  onChange,
  pinnedStyles,
  onPinnedClick,
  styleId,
  featuredStyleId,
  onFeaturedClick,
  onSelectCard,
  onPreviewClick,
  onColorClick,
  colorOverrides
}) => {
  let sortable = null; // sortable instance

  // Construct the actual item here.
  const listItems = styles.map(style => (
    <StyleCard
      key={style.id}
      style={style}
      colorOverridden={colorOverrides[style.id] ? true : false}
      showcasedStyleId={styleId}
      featuredStyleId={featuredStyleId}
      data-id={style.id}
      pinned={pinnedStyles.includes(style.id)}
      onPinnedClick={e => onPinnedClick(e, style)}
      onFeaturedClick={e => onFeaturedClick(e, style)}
      onSelectCard={e => onSelectCard(e, style)}
      onPreviewClick={e => onPreviewClick(e, style)}
      onColorClick={e => onColorClick(e, style)}
    />
  ));

  return (
    <Sortable
      // Sortable options (https://github.com/RubaXa/Sortable#options)
      options={{}}
      className="StyleCards"
      // [Optional] Use ref to get the sortable instance
      ref={c => {
        if (c) {
          sortable = c.sortable;
        }
      }}
      // [Optional] The onChange method allows you to implement a controlled component and keep
      // DOM nodes untouched. You have to change state to re-render the component.
      // @param {Array} order An ordered array of items defined by the `data-id` attribute.
      // @param {Object} sortable The sortable instance.
      // @param {Event} evt The event object.
      onChange={(order, sortable, e) => {
        const oldIndex = e.oldIndex;
        const newIndex = e.newIndex;

        onChange(oldIndex, newIndex);
      }}
    >
      {listItems}
    </Sortable>
  );
};

CardContainer.propTypes = propTypes;

export default CardContainer;
