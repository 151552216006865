/*
  CategoryEditor
  --------------

  This class provides all the functionality and nested components to allow the Merchandising team to edit
  a Category from MMS inside Catalog.
 */
import React from "react";
import Form from "../category_editor/Form";
import Spinner from "../category_editor/Spinner";
import PageHeader from "../category_editor/PageHeader";
import CardContainer from "../category_editor/CardContainer";
import DefaultColorModal from "../category_editor/DefaultColorModal";
import axios from "axios";

export default class CategoryEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: {},
      isLoading: true,
      mms_category: {},
      selectedStyle: {},
      show: false,
      styles: []
    };
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    const { endpoint_url, alignment_options } = this.props;

    axios.get(endpoint_url).then(response => {
      const jsonData = response.data;
      const { category, styles } = jsonData;
      const mmsCategory = jsonData.mms_category;

      this.setState({
        mmsCategory: mmsCategory,
        category,
        styles: styles,
        isLoading: false,
        alignmentOptions: alignment_options
      });
    }).catch(error => {
      console.log("error calling URL: " + url);
      console.log(error);
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    } else {
      return (
        <div className="CategoryEditor">
          <PageHeader name={this.state.mmsCategory.name} parentId={this.state.mmsCategory.parent_id} />
          <Form category={this.state.category}
            alignmentOptions={this.state.alignmentOptions}
            handleChange={this.handleFormChange}
            handleSubmit={this.handleFormSubmit}
            handleBack={this.handleFormBack}
            handleReset={this.handleFormReset}
          />
          <hr className="sb-Rule" />
          <CardContainer styles={this.state.styles}
            styleId={this.state.category.style_id}
            featuredStyleId={this.state.category.featured_style_id}
            onChange={this.onCardDropped}
            pinnedStyles={this.state.category.pinned_styles}
            onPinnedClick={this.onPinnedClick}
            onFeaturedClick={this.onFeaturedClick}
            onSelectCard={this.onSelectCard}
            onPreviewClick={this.onPreviewClick}
            onColorClick={this.onColorClick}
            colorOverrides={this.state.category.styles_preferred_color_overrides}
          />
          <DefaultColorModal show={this.state.show}
            handleClose={this.hideModal}
            selectedStyle={this.state.selectedStyle}
            onModalInputChange={this.onModalInputChange}
            stylePreferredColorOverrides={this.state.category.styles_preferred_color_overrides}
          />
        </div>
      );
    }
  }

  /*
    handleFormChange
    ----------------

    This function will handle the onChange events from the Form component.
    These events are triggered whenever someone writes anything in the input fields, and the change will be
    automatically reflected in the state of our Component.
   */
  handleFormChange = (event) => {
    const category = this.state.category;
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ category: {...category, [name]: value } })
  };

  /*
    handleFormSubmit
    ----------------

    This function is responsible for handling the submission of the Form child component.
    We will first of all prevent the original event from propagating further, so we don't get a page reload.
    Then we will take the state of the Category object, and post it to Rails, so it can be stored in the database.
  */
  handleFormSubmit = (event) => {
    event.preventDefault();

    const { endpoint_url } = this.props;
    const colorOverrides = {...this.state.category.styles_preferred_color_overrides};
    const pinnedStyles = [...this.state.category.pinned_styles];
    const unpinnedStyles = this.state.styles.filter(style => !pinnedStyles.includes(style.id)).map(style => style.id);
    let payload = { ...this.state.category, pinned_styles: pinnedStyles, unpinned_styles: unpinnedStyles, styles_preferred_color_overrides: colorOverrides };

    // We don't want to submit the ID as part of the parameters
    delete payload.id;

    // Send the data to the Controller using a PATCH operation on the correct URL to trigger the update statement.
    // Handle the response or any error that might occur to prevent the application from breaking.
    axios.patch(endpoint_url, { category: payload })
      .then((response) => { // Successful response, update the state with received information to trigger renders.
        const jsonData = response.data;
        const category = jsonData.category;

        this.setState({category});
      })
      .catch((error) => { // Error response, write it out to console for now
        console.log(error);
      })
  };

  /*
    handleFormBack
    --------------

    Callback to handle the user clicking the Back button.
    In which case we'll send the user back to the categories page of the CMS.
   */
  handleFormBack = () => {
    window.location.href = this.props.back_url;
  };

  /*
    handleFormReset
    ---------------

    Callback that is triggered when the user clicks the Reset! button.
    Clears the settings for the Category back to their default setting.
   */
  handleFormReset = () => {
    const category = { ...this.state.category, style_id: null, featured_style_id: null, style_type: "image_default", image_alignment: "align_right" };

    this.setState({category});
  };

  /*
    onCardDropped
    --------------

    This function handles the Callback from the Sortable Style list, and updates the array of Styles to reflect
    the drag & drop the user did with it. We basically shuffle the two Styles that got replaced in the list so
    our internal state reflects the desired behavior.
  */
  onCardDropped = (oldIndex, newIndex) => {
    const styles = [...this.state.styles];
    const style = {...styles[oldIndex]};

    // Remove the element from the old index and drop it at the new index.
    styles.splice(oldIndex, 1);
    styles.splice(newIndex, 0, style);

    this.setState({styles})
  };

  /*
    onPinnedClick
    -------------

    This function handles the callback from clicking the pin button in a StyleCard.
    Because we don't know whether the Style itself is already pinned/unpinned, we need to look up it's ID inside
    the collection of pinned styles, and update that collection accordingly based on the state.
   */
  onPinnedClick = (event, style) => {
    // Prevent the event from triggering anything else, such as a form submit.
    event.preventDefault();

    let pinnedStyles = [...this.state.category.pinned_styles];
    const styleId = style.id;

    // If the pinned_styles property contains the StyleId, remove it, so we can unpin the Style, otherwise add it,
    // so we can properly pin the Style.
    if(pinnedStyles.includes(styleId)) {
      pinnedStyles = pinnedStyles.filter(id => id !== styleId);

      // We unpinned the Style, so it should at least be moved to the top of the unpinned Styles.
      this.onCardDropped(this.state.styles.indexOf(style), pinnedStyles.length);
    } else {
      pinnedStyles.push(styleId);

      // Since we're pinning the Style, move it to the back of the pinned styles
      this.onCardDropped(this.state.styles.indexOf(style), pinnedStyles.length - 1);
    }

    // Store the new state, we will store the unpinned order once we save everything.
    this.setState({
      category: {
        ...this.state.category,
        pinned_styles: pinnedStyles
      }
    });
  };

  /*
    onFeaturedClick
    ---------------

    Callback that is triggered whenever the user clicks the Featured Button, selecting the desired Style
    as the featured Style for the Category. Will update the state so that this change is reflected.
   */
  onFeaturedClick = (e, style) => {
    e.preventDefault();

    const featuredStyleId = this.state.category.featured_style_id;

    if (style.id !== featuredStyleId) {
      this.setState({ category: { ...this.state.category, featured_style_id: style.id } });
    } else {
      this.setState({ category: { ...this.state.category, featured_style_id: null } });
    }
  }

  /*
    onSelectCard
    ------------
    Callback when the user selects a Card.
    Sets the card as the display for the Category
 */
  onSelectCard = (e, style) => {
    e.preventDefault();

    const id = style.id;

    if(this.state.category.style_id === id) {
      this.setState({ category: { ...this.state.category, style_id: null } })
    } else {
      this.setState({ category: { ...this.state.category, style_id: id } })
    }
  }

  /*
    onPreviewClick
    --------------

    Callback that is triggered when the user clicks the Preview button.
    Opens a new window to preview the category changes.
   */
  onPreviewClick = (e, style) => {
    const parentId = this.state.mmsCategory.parent_id;
    const categoryId = this.state.mmsCategory.id;
    const categoryName = this.state.mmsCategory.name.replace(/\W+/g, '-').toLowerCase();
    window.open(`/products/categories/${categoryName}/${parentId}?pcid=${categoryId}&psid=${style.id}`, "_blank");
  };

  /*
    onColorClick
    --------------

    Callback that is triggered when the user clicks the Color Override button.
    Opens the modal to update a style's preferred color for the current category.
   */
  onColorClick = (e, style) => {
    this.setState({ selectedStyle: style },
      () => {this.setState({ show: true })});
  };

  /*
    hideModal
    ----------

    Hides the color modal.
  */
  hideModal = () => {
    this.setState({ show: false });
  };

  /*
    onModalInputChange
    -------------------

    Callback that is triggered when the user is entering a color into the modal that
    will update the category.styles_preferred_color_overrides state
    */
  onModalInputChange = (colorId) => {
    let value = (colorId === "" || colorId === undefined) ? undefined : parseInt(colorId);
    if (value !== undefined && isNaN(value)) {
      return false;
    }
    if (this.state.selectedStyle?.id) {
      this.setState({ category: { ...this.state.category,
        styles_preferred_color_overrides: { ...this.state.category.styles_preferred_color_overrides,
          [parseInt(this.state.selectedStyle.id)] : value  } } } );
    }
    return true;
  };
}
