/*
  Card Component
  --------------

  The Card Component, is a PureComponent responsible for rendering the information required to set the Card on
  a specific Category inside the Catalog. This information is currently stored separately of the Category object,
  so the parent needs to send this to the correct endpoint for updating.

  Originally this Component took up the majority of the page. The new design will create a smarter component,
  allowing a better preview experience without having to scroll through the entire page. All Styles will be
  displayed inside the a list, allowing them to be selected as the active Card style, and providing a cleaner
  preview possibility inside the Admin section itself.
 */
import React from "react";
import PropTypes from 'prop-types';
import StyleButton from "./StyleButton";

const StyleCard = ({style, pinned, colorOverridden, showcasedStyleId, featuredStyleId, onPinnedClick, onFeaturedClick, onSelectCard, onPreviewClick, onColorClick}) => {
  let className = "StyleCard";
  const featured = style.id === featuredStyleId;
  const pinnedValue = pinned ? "Unpin" : "Pin";
  const showcased = style.id === showcasedStyleId;
  const showcasedValue = showcased ? "Showcased" : "Showcase";
  const colorOverrideValue =  colorOverridden ? "Color Overriden" : "Override Color"

  return (
    <div className={className}>
      <img src={style.laydown_image_url} alt={style.name} />
      <div className="admin-CategoryImg-name">{style.name}</div>
      <div className="admin-CategoryImg-actions-promote">
        <StyleButton active={pinned} displayValue={pinnedValue} onClick={onPinnedClick} />
        <StyleButton active={showcased} displayValue={showcasedValue} onClick={onSelectCard} />
        <StyleButton active={false} displayValue={'Preview Showcased'} onClick={onPreviewClick} />
        <StyleButton active={colorOverridden} displayValue={colorOverrideValue} onClick={onColorClick} />
      </div>
    </div>
  );
};

StyleCard.propTypes = {
  style: PropTypes.object,
  pinned: PropTypes.bool,
  colorOverridden: PropTypes.bool,
  onPinnedClick: PropTypes.func,
  onColorClick: PropTypes.func
};

export default StyleCard;
