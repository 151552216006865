/*
  PageHeader
  ----------

  The PageHeader component, is a basic Presenter Component that knows how to properly write out the page header,
  based on the Category information that's available from MMS. Requires the MMS Category parentID and Name to be
  passed down as a prop.
*/
import React from "react";

export default class PageHeader extends React.PureComponent {
  render() {
    const sub = this.props.parentId === null ? "" : "Sub";
    const name = this.props.name;

    return <h1>Edit {sub} Category - {name}</h1>
  }
}
