/*
  StyleButton
  ----------------------------------------------------------------------------------------------------------------------

  Renders a button on the Category Editor for a Style with the defined functionality.

  Props
  ----------------------------------------------------------------------------------------------------------------------

  *
 */
import React from "react";
import PropTypes from 'prop-types';

const StyleButton = ({active, onClick, displayValue}) => (
  <button
    onClick={onClick}
    className={active ? "sb-Btn sb-Btn--small" : "sb-Btn sb-Btn--secondaryAlt sb-Btn--small"}
  >
    {displayValue}
  </button>
);

StyleButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  displayValue: PropTypes.string.isRequired
};

export default StyleButton;
