/*
  CategoryEditor - Form
  ---------------------

  This React Component is responsible for allowing specific information about a Category to be stored inside
  the Catalog application. This information is currently stored inside the MMS application, but the Catalog
  app has the possibility to overwrite this behavior as needed.

  The Component is a presentation component, meaning we will force it to be a PureComponent and rely on the
  required event handler to be passed down from the parent.
*/
import React from "react";
import PropertyField from "./form/PropertyField";
import TextArea from "./form/TextArea";

export default class Form extends React.PureComponent {
  render() {
    const {subtitle, title, description, slug, page_content, style_type, image_alignment, target_url} = this.props.category;
    const alignmentOptions = this.props.alignmentOptions

    const formatOption = option => {
      const alignment = option.split('_')[1];
      return alignment.charAt(0).toUpperCase() + alignment.slice(1);
    };

    return (
      <div className="CategoryEditorForm">
        <h4>Custom Content</h4>
        <form className="sb-Form" onSubmit={this.props.handleSubmit}>
          <PropertyField label="Page Title" type="text" name="title" value={title} onInputChanged={this.props.handleChange} />
          <PropertyField label="Page Subtitle" type="text" name="subtitle" value={subtitle} onInputChanged={this.props.handleChange} />
          <PropertyField label="Slug" type="text" name="slug" value={slug} onInputChanged={this.props.handleChange} />
          <TextArea label="Description" type="text" name="description" value={description} onInputChanged={this.props.handleChange} />
          <TextArea label="Page Content" name="page_content" value={page_content} onInputChanged={this.props.handleChange} />
          <PropertyField label="Target URL" type="text" name="target_url" value={target_url} onInputChanged={this.props.handleChange} />
          <div className="sb-Form-group">
            <label>Style Display Type</label>
            <select value={style_type} name="style_type" onChange={this.props.handleChange}>
              <option value="image_default">Default Image</option>
              <option value="image_full">Full Image</option>
            </select>
          </div>
          <div className="sb-Form-group">
            <label>Image Alignment (only affects double cards on /products page)</label>
            <select value={image_alignment} name="image_alignment" onChange={this.props.handleChange}>
              {alignmentOptions.map((alignmentOption, index) => (
                <option key={index} value={alignmentOption}>{formatOption(alignmentOption)}</option>
              ))}
            </select>
          </div>
          <div className="sb-Form-group sb-Form-group--buttons">
            <button type="button" className="sb-Btn" name="back" onClick={this.props.handleBack}>Back</button>
            <input type="submit" name="commit" value="Save" />
            <button type="button" className="sb-Btn sb-Btn--small" name="reset" onClick={this.props.handleReset}>Reset!</button>
          </div>
          <p>
            The "Reset!" button will delete any saved data for this card's presentation and ensure the first style is always used. The select menu above will choose the presentation style for this category card. (affects 1x2 card only)
          </p>
        </form>
      </div>
    );
  }
}
